/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../../../../components/layout';
import Navbar from '../../../../components/navbar/navbar';
import MobileNavbar from '../../../../components/mobile-navbar/mobile-navbar';

import {
  Button,
  ColoredSection,
  DisplayText,
  Header,
  HeroImage,
  LayoutUI,
  LeadText,
  Stack,
  Subheader,
  TextStyle
} from '../../../../ui-kit';

import './education-program.scss';

const EducationProgram = ({ data }) => {
  // state = {
  //   selectedTab: 0
  // };

  // const handleIconClick = (index) => {
  //   this.setState({ selectedTab: index });
  // }

  const {
    // metaData,
    hero,
    accentColor,
    icons,
    iconSection,
    program,
    preferredImageTitle,
    preferredProgramImage,
    preferredProgramLink,
    preferredProgramText,
    orderNowLink
  } = data.contentfulPageEduProgram;

  // const { tabHeading, tabImage, tabText } = tabContents[
  //   this.state.selectedTab
  // ];

  return (
    <Layout>
      <Navbar color="white" />
      <MobileNavbar color="white" />
      <div className="page--education-program">
        <HeroImage image={hero.images[0].file.url} />
        <LayoutUI narrow>
          <TextStyle center>
            <Stack vertical spacing="loose">
              <DisplayText size="small">{hero.title}</DisplayText>
              <LeadText>{hero.subtitle.subtitle}</LeadText>
            </Stack>
          </TextStyle>
        </LayoutUI>

        <LayoutUI>
          <Stack vertical spacing="loose">
            <div className="TabButtons">
              {icons.map(({ label, image }) => (
                <div
                  // onClick={() => this.handleIconClick(index)}
                  className="IconContainer"
                  key={label}
                >
                  <img
                    src={image.file.url}
                    alt={image.title}
                    className="Icon"
                  />
                  <div>
                    <span
                      style={{
                        // borderBottom:
                        //   index === this.state.selectedTab
                        //     ? `2px solid ${COLORS[accentColor.toUpperCase()]}`
                        //     : 'none',
                        display: 'inline-block'
                      }}
                    >
                      <Subheader>{label}</Subheader>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </Stack>
        </LayoutUI>

        <LayoutUI>
          {iconSection.map(icon => (
            <div className="TabContainer" key={icon.heading}>
              <div className="TabImage">
                <img src={icon.image.file.url} alt={icon.image.title} />
              </div>

              <div className="TabContent">
                <Stack spacing="extraLoose" vertical>
                  <Header size="small">{icon.heading}</Header>
                  {icon.longText && (
                    <LeadText alignment="left">
                      {icon.longText.longText}
                    </LeadText>
                  )}
                </Stack>
              </div>
            </div>
          ))}
        </LayoutUI>

        <ColoredSection color={accentColor}>
          <LayoutUI>
            <Stack
              alignment="center"
              className={!orderNowLink ? 'JustifyCenter' : ''}
            >
              <Stack.Item fill>
                <Header>{orderNowLink.text}</Header>
              </Stack.Item>
              <Button color={`${accentColor}-dark`} href={orderNowLink.link}>
                Order now
              </Button>
            </Stack>
          </LayoutUI>
        </ColoredSection>

        {program !== 'young-adult' ? (
          <div>
            <HeroImage image={preferredProgramImage.file.url} noMargin />
            <LayoutUI>
              <div className="PreferredSchoolContainer">
                <Stack vertical spacing="loose">
                  <Header>{preferredImageTitle}</Header>

                  {preferredProgramText && (
                    <LeadText alignment="left">
                      {preferredProgramText.preferredProgramText}
                    </LeadText>
                  )}

                  <Button color="Black" href={preferredProgramLink}>
                    Learn More
                  </Button>
                </Stack>
              </div>
            </LayoutUI>
          </div>
        ) : (
          <div className="PreferredContainer">
            <div
              className="PreferredImage"
              style={{
                backgroundImage: `url(${preferredProgramImage.file.url})`
              }}
            />
            <div className="PreferredContent">
              <Stack spacing="extraLoose" vertical>
                <Header>{preferredImageTitle}</Header>
                {preferredProgramText && (
                  <LeadText alignment="left">
                    {preferredProgramText.preferredProgramText}
                  </LeadText>
                )}
                <Button color="Black" href={preferredProgramLink}>
                  Apply
                </Button>
              </Stack>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

EducationProgram.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired
};

export default EducationProgram;

export const educationProgramQuery = graphql`
  query educationProgramQuery($slug: String!) {
    contentfulPageEduProgram(slug: { eq: $slug }) {
      slug
      metaData {
        metaTitle
        metaImage {
          title
          file {
            url
          }
        }
        metaDescription
      }
      hero {
        title
        images {
          file {
            url
          }
          title
          description
        }
        subtitle {
          subtitle
        }
      }
      accentColor
      icons {
        label
        image {
          file {
            url
          }
          title
        }
      }
      iconSection {
        heading
        image {
          file {
            url
          }
          title
        }
        longText {
          longText
        }
      }
      preferredImageTitle
      preferredProgramImage {
        description
        file {
          url
        }
        title
      }
      preferredProgramLink {
        link
        text
      }
      preferredProgramText {
        preferredProgramText
      }
      orderNowLink {
        text
        link
      }
    }
  }
`;
